import React, { useReducer, useEffect } from 'react';
import { ContextStore, initialState } from 'store/ContextStore';
import { useWindowSize } from '@react-hook/window-size';
import { ThemeProvider } from 'styled-components';
import theme from 'utility/theme';
import { padWidthNum } from 'utility/rwd';
import { reducer } from 'store/reducer';
import { Helmet } from 'react-helmet';
import { favicon } from 'assets/img';
import {
  Header,
  GlobalStyle,
  Banner,
  MoreInfo,
  Overview,
  Features,
  ApplicableModel,
  Pricing,
  Footer,
} from 'components';

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [width] = useWindowSize();

  useEffect(() => {
    if (width <= padWidthNum) {
      dispatch({ type: 'togglePhoneSize', isPhoneSize: true });
    } else {
      dispatch({ type: 'togglePhoneSize', isPhoneSize: false });
    }
  }, [width]);

  useEffect(() => {
    const handleScroll = () => {
      dispatch({ type: 'getScrollY', scrollY: window.scrollY });
    };
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <ContextStore.Provider value={{ state, dispatch }}>
      <Helmet title='MagiCollect'>
        <meta name='description' content='MagiCollect' />
        <link rel='apple-touch-icon' href={favicon} />
      </Helmet>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Header />
        <Banner />
        <MoreInfo />
        <Overview />
        <Features />
        <ApplicableModel />
        {/* <Pricing /> */}
        <Footer />
      </ThemeProvider>
    </ContextStore.Provider>
  );
};
