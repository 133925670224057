const theme = {
  blue: '#2762bd',
  sidebarBlue: '#1b2a74',
  sidebarLanguageItemBlue: 'rgba(39, 98, 189, 0.3)',
  sidebarLanguageItemHoverBlue: 'rgba(39, 98, 189, 0.8)',
  orange: '#f59128',
  orangeHover: '#ffad5a',
  buttonOrange: '#e79016',
  sidebarOrange: '#e79016',
  headerNavColor: 'rgba(255, 255, 255, 0.7)',
  headerNavColorHover: 'white',
  bannerColor: '#ecf0f1',
  titleGary: '#212121',
  introductionTitleGary: '#333',
  introductionGary: '#86878b',
  actionGary: '#e8e8e8',
  actionHoverGary: 'rgba(232, 232, 232, 0.5)',
  backgroundGary: '#f8f8f8',
  pricingYearGary: 'rgba(255, 255, 255, 0.5)',
  pricingYearBgGary: '#333',
  pricingCotentGary: '#f7f6f7',
};
export default theme;
