import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ApplicableModelArr from './ApplicableModelArr';
import StyledTitle from 'components/StyledTitle';
import IntersectionVisible from 'react-intersection-visible';
import { rightIcon } from 'assets/img';
import {
  StyledContainer,
  StyledApplicableModelCotainer,
  StyledApplicableModel,
  StlyedButtonBox,
} from './StyledApplicableModel';
const ApplicableModel = () => {
  const { t } = useTranslation();
  const [scrollToThisElement, setScrollToThisElement] = useState(false);
  const onShow = (e) => {
    if (e && !scrollToThisElement) {
      setScrollToThisElement(true);
    }
  };
  return (
    <IntersectionVisible onShow={onShow} options={{ rootMargin: '-20%' }}>
      <StyledContainer name='applicableModel'>
        <StyledTitle scrollToThisElement={scrollToThisElement}>{t('applicableModel.title')}</StyledTitle>
        <StyledApplicableModelCotainer scrollToThisElement={scrollToThisElement}>
          {ApplicableModelArr.map((applicableModel, applicableModelIndex) => (
            <StyledApplicableModel key={applicableModel.title}>
              <img src={applicableModel.imgSrc} alt={applicableModel.title} />
              <h6>{applicableModel.title}</h6>
              <ul>
                {applicableModel.introduction.map((introductionString, i) => {
                  return (
                    <li key={`introductionString-${i}`}>
                      {t(`applicableModel.${applicableModel.introductionTitle}.${introductionString}`)}
                    </li>
                  );
                })}
              </ul>
              <StlyedButtonBox>
                <a href={applicableModel.href} target={applicableModel.title}>
                  {t('applicableModel.moreDetail')}
                  <img src={rightIcon} alt='' />
                </a>
              </StlyedButtonBox>
            </StyledApplicableModel>
          ))}
        </StyledApplicableModelCotainer>
      </StyledContainer>
    </IntersectionVisible>
  );
};

export default ApplicableModel;
